.filter {
  padding: 0px 10%;

  .mat-button {
    width: 100%;
    margin: 1.25em 0;
    padding: 0px;
    text-align: left;

    table {
      width: 100%;

      #entity-name {
        text-align: left;
      }

      #icon {
        text-align: right;

        .mat-select-arrow {
          margin: unset;
        }
      }
    }
  }

  .mat-form-field {
    width: 100%;
  }
  
}

mat-label {
  color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item {
  font-size: 15px !important;
  line-height: 1em !important;
}


.district-mat-menu-item {
  overflow: visible !important;
}

// multi-select filters
// override background color for multi-select select all
// .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//   background-color: #2196f3 !important;
// }
// .mat-pseudo-checkbox-checked {
//   background-color: #2196f3 !important;
//   background: #2196f3 !important;
// }

.multi-filter-card {
  display: flex !important; 
  flex-direction: column; 
  padding: 0 !important;
  z-index: 2000;
  position: relative;
}

.multi-filter-card-header {
  display: flex;
  flex-shrink: 0;
}

.multi-filter-card-content {
  display: flex !important; 
  flex-direction: column; 
  overflow: auto; 
  flex-grow: 1; 
  margin-bottom: 0px !important;
  max-height: 200px;
  min-height: 88px;
}

.multi-filter-card-footer {
  display: flex; 
  flex-direction: row; 
  padding: 0px  !important; 
  margin-right: 1px  !important; 
  flex-shrink: 0  !important;
  // min-height: 65px;
  margin-top: 0px !important;
  margin-bottom: -6px !important;
}

.multi-filter-card-actions {
  width: 100%;     
  margin-left: -2px !important; 
  margin-top: 10px !important; 
  padding: 0px !important;
}

.multi-filter-apply-button {
  background-color: $brand-primary !important;
  color: white !important;  
  height: 27.8px;
  width: 68px;
  text-align: center;
  vertical-align: middle;
  line-height: 27.8px !important;
}

.multi-filter-apply-button[disabled] {
  background-color: lightgrey !important;
  color: white !important;  
  height: 27.8px;
  width: 68px;
  text-align: center;
  vertical-align: middle;
  line-height: 27.8px !important;
}

.multi-filter-cancel-button {
  background-color: white !important;    
  margin-right: 10px !important;  
  height: 27.8px;
  width: 68.4px;
  text-align: center;
  vertical-align: middle;
  line-height: 27.8px !important;
}

.multi-filter-option {
  line-height: 48px !important; 
  min-height: 48px !important;
}

.multi-filter-select-panel {  
  overflow: hidden !important;
  max-height: 266px !important;
}